import api from './api'
export default { 
  add(data) {
    return api.execute(`post`,`/moderator/provider`, data)
  },
  update(data) {
    return api.execute(`put`,`/moderator/provider`, data)
  },
  get() {
    return api.execute('get', `/moderator/provider`)
  },
  remove(data) {
    return api.execute(`delete`,`/moderator/provider`, data)
  }
}