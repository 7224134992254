<template>
  <div>
    <Breadcrumbs main="" title="Services" />
      <b-row>
        <b-col>
          <b-card style="overflow: auto;">
            <template #header>
              <!-- <h2 class="mb-0">
                <b-badge variant="dark" class="text-white text-left">[Current Month] | [Last Month]</b-badge>
              </h2> -->
              <b-row>
                  <b-col class="col-md-3 col-12">
                    <b-form-group label="Provider">
                      <multiselect
                      :options="providerlist"
                      label="name"
                      v-model="selectedProvider"
                      placeholder="Select service provider"
                      :multiple="false"
                      :close-on-select="true"
                      track-by="_id"
                      >
                      </multiselect>
                    </b-form-group>
                  </b-col>
                  <b-col class="col-md-3 col-12">
                    <b-form-group label="Category">
                      <multiselect
                      :options="categoryArray"
                      label="name"
                      v-model="selectedCategory"
                      placeholder="Select Category"
                      :multiple="false"
                      :close-on-select="true"
                      track-by="id"
                      >
                      </multiselect>
                    </b-form-group>
                  </b-col>
                  <b-col class="col-md-3 col-12">
                    <b-form-group label="Type">
                      <multiselect
                      :options="adTypeArray"
                      label="name"
                      v-model="selectedAdType"
                      placeholder="Select Ad Type"
                      :multiple="false"
                      :close-on-select="true"
                      track-by="id"
                      >
                      </multiselect>
                    </b-form-group>
                  </b-col>
                  <b-col class="col-md-3 col-12">
                  <b-form-group label="Date Range">
                 
                    <date-range-picker
                              style="display: inline;"
                              ref="picker"
                              opens="left"
                              :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy HH:mm:ss' }"
                              :singleDatePicker="false"
                              :timePicker="false"
                              :timePicker24Hour="false"
                              :showWeekNumbers="false"
                              :showDropdowns="true"
                              :autoApply="true"
                              v-model="dateRange"
                              @update="ChangedDate()"
                              :linkedCalendars="false"
                              :ranges="customDateRanges"
                              :max-date="new Date()"
                    >
                      <template v-slot:input="picker" style="min-width: 350px;">
                        <span style="font-size: 80%;"> {{ changeDateFormate(dateRange.startDate) }} - {{ changeDateFormate(dateRange.endDate) }}</span> 
                      </template>
                    </date-range-picker> 
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                      <b-button @click="filterData" variant="primary">Filter</b-button>
                      <b-button class="ml-2" @click="init()" variant="primary">Reset</b-button>
                </b-col>
              </b-row>
            </template>
            <div class="col-md-12" v-if="isBusy">
                <h6 class="sub-title mb-0 text-center">Loading...</h6>
                  <div class="loader-box">
                    <div class="loader-30"></div>
                  </div>
              </div> 
              <div v-else>
            <b-list-group
              horizontal="md"
              class="d-flex flex-wrap justify-content-left"
              v-if="serData.length != 0"
            >
              <b-list-group-item
                class="col-md-3 col-12"
                v-for="(item, index) in serData"
                :key="item.key"
                :class="index % 2 === 0 ? 'even' : 'odd'"
              >
                <h5  @click.prevent="editTable(item)" class="f-w-600 text-primary" style="cursor: pointer;">
                  <span title="Provider"> {{ sentenceCase(item.provider_name) }}</span> - <span title="Service">{{ sentenceCase(item.title) }}</span>
                  <b-badge variant="danger" title="Current Month  |  Last Month" class="ml-2" pill>{{
                    `  ${
                      currentMonth[item._id]
                        ? currentMonth[item._id]["total"]
                        : 0
                    } | ${
                      lastMonth[item._id] ? lastMonth[item._id]["total"] : 0
                    } `
                  }}</b-badge>
                </h5>
              </b-list-group-item>
            </b-list-group>

            <span v-else> No Data Found </span>
          </div>
<service-list></service-list>
            <!-- <b-table :items="serData" :busy="isBusy" :fields="serFields"  :per-page="perPage"
      :current-page="currentPage"  outlined>
     <template #cell(_id)="data">
      
        <b @click.prevent="editTable(data)"><h4>{{` ${data.item.provider_name } -  ${data.item.title} `}} </h4> </b>
    
      </template>
      <template #cell(total)="data">
        <h5 class="mr-10"> {{`[ ${currentMonth[data.item._id] ? currentMonth[data.item._id]["total"] : 0} | ${lastMonth[data.item._id] ? lastMonth[data.item._id]["total"] : 0} ]` }}</h5>
      </template>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
    </b-table>  -->
            <!-- <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
      class="mt-2"
    ></b-pagination> -->
          </b-card>
        </b-col>
      </b-row>
  </div>
</template>
<script>


// Tables
import SocialTrafficTable from "./Dashboard/SocialTrafficTable";
import PageVisitsTable from "./Dashboard/PageVisitsTable";

// Services
import serviceApi from "../../services/ServiceInfo";
import ContentView from "./Dashboard/Content.vue";
import ServiceTable from "../views/Dashboard/ServicesTable.vue";
import ServiceList from "../views/ServicesList.vue";
import config from "../../services/globalLit";
import ServiceProviderServices from "../../services/ServiceProviderServices";
import Multiselect from "vue-multiselect";
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import moment from 'moment';
export default {
  components: {
    ServiceList,
    PageVisitsTable,
    SocialTrafficTable,
    ContentView,
    ServiceTable,
    Multiselect,
    DateRangePicker
  },
  data() {
    return {
      dateRange:{
        startDate: new Date(), 
        endDate: new Date().setMonth(new Date().getMonth() - 2)
      },
      customDateRanges: {
        'Today': [new Date(), new Date()],
        'Yesterday': [
          new Date(new Date().setDate(new Date().getDate() - 1)),
          new Date(new Date().setDate(new Date().getDate() - 1))
        ],
        'This Month': [
          new Date(new Date().getFullYear(), new Date().getMonth(), 1),
          new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
        ],
        'This Year': [
          new Date(new Date().getFullYear(), 0, 1),
          new Date(new Date().getFullYear(), 11, 31)
        ],
        'Last Month': [
          new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
          new Date(new Date().getFullYear(), new Date().getMonth(), 0)
        ]
      },
      userdata:[],
      isBusy: false,
      perPage: 10,
      rows: 100,
      currentPage: 1,
      serData: [],
      currentMonth: {},
      lastMonth: {},
      serFields: [
        {
          key: "_id",
          label: "Services",
        },
        {
          key: "total",
          label: "[currentMonth]|[lastMonth]",
        },
      ],
      userActivateStatus: false,
      user: "",
      user_status: false,
      fields: [
        {
          key: "link",
          label: "Links",
          sortable: true,
          editable: true,
          tbClass: "bTableThStyle",
        },
        {
          key: "copy",
          label: "action",
          tbClass: "acClass",
        },
      ],
      selectedProvider: null,
      selectedCategory: null,
      selectedAdType: null,
      filter:{},
      providerlist: [],
      adTypeArray: config.adTypeArray,
      categoryArray: config.categorylist,
    };
  },
  methods: {
   async filterData(){
  
      this.filter.spid = this.selectedProvider?this.selectedProvider._id:null;
      this.filter.category = this.selectedCategory?this.selectedCategory.id:null;
      this.filter.adtype = this.selectedAdType?this.selectedAdType.id:null;
      this.filter.sdate = this.dateRange.startDate;
      this.filter.edate = this.dateRange.endDate;
      this.init('filter');
    },
    sentenceCase(str) {
      if (typeof str !== 'string') return str
      return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase())
    },
    editTable(item) {
     
      item.sdate = new Date(this.dateRange.startDate).toISOString();
      item.edate = new Date(this.dateRange.endDate).toISOString();

      this.$root.$emit("showtrxforlist",item);
      this.$bvModal.show("openSerList");
      return;
    },
    decodeString(str) {
      var div = document.createElement("textarea");
      div.innerHTML = str;
      var decoded = div.firstChild.nodeValue;

      return decoded;
    },
    maxWidth() {
      return "text-truncate";
    },
    showModal(item) {
      this.addetail = item;
      this.videoUrl = `${process.env.VUE_APP_BACKHOST}:${process.env.VUE_APP_BACKPORT}/videos/sr_${item["_id"]}.mp4`;
    },
    async getChains(id) {
      let data = await dashbordService.chains(id);
      this.linkschain = data.data.map((e) => {
        return { link: e };
      });
    },
    copyText(link) {
      navigator.clipboard.writeText(link);
      this.$notify({
        type: "info",
        message: "Copied to clipboard",
      });
    },
    async init(txt) {
      this.isBusy = true;
      var payload = {};
      if(txt){
         payload = this.filter;
      }
      
      try {
      } catch (e) {
        console.error("Error in getting monthly services");
        console.error(e.message);
      }
      let response = await serviceApi.getMonthlyServices(payload);
      if (response.result) {
        this.currentMonth = response.data.cMonth;
        this.lastMonth = response.data.lMonth;
        // this.serData = response.data.servicesList;
        let serData = response.data.servicesList;
        this.serData = [];
        serData.map((e) => {
          if(this.currentMonth[e._id] || this.lastMonth[e._id]){
            this.serData.push(e)
          }
        });
      }
      this.isBusy = false;
    },
   async getSplist(){
      let spData = await ServiceProviderServices.get();
      if (spData.result) {
        this.providerlist = spData.data;
      }
    },
    ChangedDate(){
      let sdate = new Date(
        this.dateRange.startDate.getTime() - this.dateRange.startDate.getTimezoneOffset() * 60000
      ).toISOString();

      let edate = new Date(
        this.dateRange.endDate.getTime() - this.dateRange.endDate.getTimezoneOffset() * 60000
      ).toISOString();
      this.dateRange.startDate = sdate
      this.dateRange.endDate = edate
      // this.filterData(this.monitor_id, this.dateRange)
    },
    changeDateFormate(d){
        return moment.utc(d).format('D/M/YYYY')
    },
  },
  mounted() {
    this.getSplist();
    this.init();
    this.userdata = JSON.parse(window.localStorage.getItem("userinfo"));
    this.userActivateStatus = this.userdata.isactive;
  },
};
</script>
<style>
.card .card-header span {
  color: #000000 !important;
  font-size: 14px;
}
</style>
<style scoped>
.text-blue {
  color: #1d75bc;
}
.table .acClass {
  background-color: red;
}
.table .bTableThStyle {
  background-color: blue !important;
  /* max-width: '100px' !important; */
  text-overflow: ellipsis !important;
}
.table > tbody > tr > td {
  max-width: 350px !important;
  /* text-justify: auto; */
  width: 1000px;
  /* white-space: nowrap; */
  overflow: auto;
  text-overflow: ellipsis;
}
.modal-dialog-1 {
  position: fixed;
  margin: auto;

  height: 100%;
  right: 0px;
}
.modal-content-1 {
  height: 100%;
}
/* .el-table .cell {
  max-width: 150px;
  padding-left: 0px;
  padding-right: 0px;
} */
</style>

<style>
  .even {
    background-color: #dae5e9;
  }
  .odd {
    background-color: #d6d4d4;
  }
</style>